import { GraphQLClient, ClientContext } from 'graphql-hooks'
import { useAuthContext } from '../useAuth/AuthProvider'

const {
  H4B_GQL_SECURE: GQL_SECURE,
  H4B_GQL_URL: GQL_URL,
  H4B_GQL_MOUNT: GQL_MOUNT
} = h4benv

const gqlUrl = `http${GQL_SECURE ? 's' : ''}://${GQL_URL}/${GQL_MOUNT}`

const client = new GraphQLClient({
  url: gqlUrl
})

type Props = {
  children: JSX.Element
}

const QueryProvider = ({ children }: Props) => {
  const {
    user: { token },
    parsedToken
  } = useAuthContext()

  client.setHeaders({
    Authorization: `Bearer ${token}`,
    'application-id': parsedToken?.claims.app ?? ''
  })

  return (
    <ClientContext.Provider value={client}>{children}</ClientContext.Provider>
  )
}

export default QueryProvider
