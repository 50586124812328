const modalStore = (store) => {
  store.on('@init', () => ({
    modal: {
      callbackData: {}
    }
  }))

  store.on('modal/callback', ({ modal }, { callbackKey, callbackData }) => ({
    modal: {
      ...modal,
      callbackData: { ...modal.callbackData, [callbackKey]: callbackData }
    }
  }))
}

export default modalStore
