import { useState, useEffect, useContext, createContext } from 'react'
import createAuth0Client, {
  Auth0Client,
  Auth0ClientOptions
} from '@auth0/auth0-spa-js'
import { Auth0User, ParsedToken } from '@custom-types'
import { AUTH_REDIRECT, passwordChange, onRedirectCallback } from './utils'

const {
  H4B_AUTH_DOMAIN: AUTH_DOMAIN,
  H4B_AUTH_CLIENT_ID: AUTH_CLIENT_ID,
  H4B_AUTH_AUDIENCE: AUTH_AUDIENCE
} = h4benv

const initOpts: Auth0ClientOptions = {
  domain: AUTH_DOMAIN,
  client_id: AUTH_CLIENT_ID,
  redirect_uri: AUTH_REDIRECT,
  audience: AUTH_AUDIENCE,
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
  onRedirectCallback
}

interface Context {
  user: Auth0User
  auth0Client?: Auth0Client
  parsedToken?: ParsedToken
  passwordChange: (email: string) => Promise<Response>
  setUser?: React.Dispatch<React.SetStateAction<Auth0User>>
  setParsedToken?: React.Dispatch<React.SetStateAction<ParsedToken | undefined>>
}

interface State {
  auth0Client: Auth0Client
  user: Auth0User
  parsedToken: ParsedToken
}

type Props = {
  children: React.ReactNode
}

const DEFAULT_CONTEXT: Context = {
  user: {
    userData: {},
    isLoaded: false,
    isAuthenticated: false,
    token: ''
  },
  passwordChange
}

const authContext = createContext(DEFAULT_CONTEXT)

const AuthProvider = ({ children }: Props) => {
  const [auth0Client, setAuth0CLient] = useState<State['auth0Client']>()
  const [user, setUser] = useState<State['user']>(DEFAULT_CONTEXT.user)
  const [parsedToken, setParsedToken] = useState<State['parsedToken']>()

  useEffect(() => {
    ;(async () => {
      const auth0FromHook = await createAuth0Client(initOpts)
      setAuth0CLient(auth0FromHook)
    })()
  }, [])

  return (
    <authContext.Provider
      value={{
        auth0Client,
        user,
        parsedToken,
        setUser,
        setParsedToken,
        passwordChange
      }}
    >
      {children}
    </authContext.Provider>
  )
}

export const useAuthContext = () => useContext(authContext)
export default AuthProvider
