import { lazy } from 'react'

const componentsMap = new Map()

const getPageComponent = (moduleToLoad: string) => {
  if (componentsMap.has(moduleToLoad)) {
    return componentsMap.get(moduleToLoad)
  }

  let PageComponent = null

  switch (moduleToLoad) {
    case 'authorization':
      PageComponent = lazy(() => import('./Auth'))
      break
    case 'login':
      PageComponent = lazy(() => import('./Login'))
      break
    case 'logout':
      PageComponent = lazy(() => import('./Logout'))
      break
    case 'notFound':
      PageComponent = lazy(() => import('./NotFound'))
      break
    case 'serverError':
      PageComponent = lazy(() => import('./ServerError'))
      break
    case 'payment-requests':
      PageComponent = lazy(() => import('./PaymentRequests'))
      break
    case 'support-cases':
      PageComponent = lazy(() => import('./SupportCases'))
      break
    case 'customers':
      PageComponent = lazy(() => import('./Customers'))
      break
    case 'charge-requests':
      PageComponent = lazy(() => import('./ChargeRequests'))
      break
    case 'physical-acq-charges':
      PageComponent = lazy(() => import('./PhysicalAcqCharges'))
      break
    case 'chargeback-cases':
      PageComponent = lazy(() => import('./Chargebacks'))
      break
    case 'real-time-dashboard':
      PageComponent = lazy(() => import('./RealTimeDashboard'))
      break
    case 'refund-requests':
      PageComponent = lazy(() => import('./Refunds'))
      break
    case 'users-management':
      PageComponent = lazy(() => import('./UsersManagement'))
      break
    case 'roles-management':
      PageComponent = lazy(() => import('./RolesManagement'))
      break
    case 'error-messages-management':
      PageComponent = lazy(() => import('./ErrorMessagesManagement'))
      break
    case 'notAuthorized':
      PageComponent = lazy(() => import('./NotAuthorized'))
      break
    default:
      PageComponent = lazy(() => import('./NotFound'))
  }

  componentsMap.set(moduleToLoad, PageComponent)
  return PageComponent
}

export default getPageComponent
