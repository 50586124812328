import { useEffect, useState } from 'react'

import { ReactComponent as SunIcon } from '/public/svg/sun.svg'
import { ReactComponent as MoonIcon } from '/public/svg/moon.svg'

import styles from './styles'

enum Theme {
  light = 'light',
  dark = 'dark'
}

const SwitchButton: React.FC = () => {
  const [theme, setTheme] = useState<Theme>(() => {
    const theme = localStorage.getItem('theme')

    if (theme) {
      return theme as Theme
    }

    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return Theme.dark
    }

    return Theme.light
  })

  useEffect(() => {
    if (theme === Theme.dark) {
      document.body.classList.add('dark')
    } else {
      document.body.classList.remove('dark')
    }
  }, [theme])

  const handleChangeTheme = () => {
    if (theme === Theme.dark) {
      localStorage.setItem('theme', Theme.light)
      setTheme(Theme.light)
    } else {
      localStorage.setItem('theme', Theme.dark)
      setTheme(Theme.dark)
    }
  }

  return (
    <div
      className={`change-theme ${styles} ${
        theme === Theme.dark ? 'dark-mode' : 'light-mode'
      }`}
      onClick={handleChangeTheme}
    >
      <div className="icon">
        <div className="ball">
          {theme === Theme.dark ? <MoonIcon /> : <SunIcon />}
        </div>
      </div>
    </div>
  )
}

export default SwitchButton
