import React, { Suspense } from 'react'
import { Layouts, FullLoading } from '@h4b-dev/components'
import { navigate } from '@store'

type Props = {
  Component: React.ElementType
  title: string
  returnTo: string
  props: any
  returnComponent: React.ElementType
}

const SusModal = ({
  Component,
  title,
  returnTo,
  props,
  returnComponent
}: Props) => (
  <Layouts.Modal
    title={title}
    onClose={() => navigate({ url: returnTo, returnComponent })}
  >
    <Suspense fallback={<FullLoading />}>
      {Component ? <Component {...props} isModal={returnTo} /> : null}
    </Suspense>
  </Layouts.Modal>
)

export default SusModal
