import { css } from '@linaria/core'

const styles = css`
  @apply flex items-center justify-center w-[50px] rounded-full bg-[#ccc] cursor-pointer transition-all p-1;

  &.dark-mode {
    @apply bg-white/10;

    & .icon {
      transform: translateX(22.5px);

      & .ball {
        @apply bg-custom-loader-100;

        & > svg {
          & > path {
            @apply stroke-white;
          }
        }
      }
    }
  }

  &.light-mode {
    @apply bg-[#ddd];

    & .icon {
      transform: translateX(0px);
    }
  }

  & .icon {
    @apply flex items-center w-full transition-all;

    & .ball {
      @apply flex items-center justify-center w-[20px] h-[20px] bg-white 
            rounded-full bg-white text-[0.65rem] transition-all;

      & > svg {
        @apply w-4 h-4;
      }
    }
  }
`

export default styles
