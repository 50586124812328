import jwt_decode from 'jwt-decode'
import { navigate } from '@store'

export interface Token {
  claims: {
    roles: string[]
    name: string
    email: string
    enviroment: string
    app: string
    app_metadata: {
      payments_monitor: {
        countries: string[]
        verticals: string[]
      }
      admin_h4b_dev: {}
    }
  }
  iss: string
  sub: string
  aud: string[]
  iat: number
  exp: number
  azp: string
  scope: string
  permissions: string[]
}

const {
  H4B_AUTH_DOMAIN: AUTH_DOMAIN,
  H4B_AUTH_CLIENT_ID: AUTH_CLIENT_ID,
  H4B_AUTH_REDIRECT_PATH: AUTH_REDIRECT_PATH
} = h4benv

export const AUTH_REDIRECT = `${window.location.protocol}//${window.location.host}${AUTH_REDIRECT_PATH}`

export const passwordChange = (email: string) =>
  fetch(`https://${AUTH_DOMAIN}/dbconnections/change_password`, {
    method: 'POST',
    headers: {
      'auth0-client': AUTH_CLIENT_ID,
      'content-type': 'application/json'
    },
    referrerPolicy: 'no-referrer-when-downgrade',
    body: JSON.stringify({
      client_id: AUTH_CLIENT_ID,
      email,
      connection: 'Username-Password-Authentication'
    }),
    mode: 'cors',
    credentials: 'omit'
  })

export const onRedirectCallback = () => navigate('/')

export const parseJwt = (token: string): Token => {
  try {
    const decodedToken: any = jwt_decode(token)
    const tokenClaims = getClaims(decodedToken)

    return {
      claims: tokenClaims,
      iss: decodedToken.iss,
      sub: decodedToken.sub,
      aud: decodedToken.aud,
      iat: decodedToken.iat,
      exp: decodedToken.exp,
      azp: decodedToken.azp,
      scope: decodedToken.scope,
      permissions: decodedToken.permissions
    } as Token
  } catch (error) {
    console.error('Invalid token')
    return {} as Token
  }
}

export const getClaims = (parsedToken: any) => {
  const tokenArr = Object.entries(parsedToken)
  const onlyClaimsTokenArr = tokenArr.filter(([key, _]) =>
    key.includes('claims')
  )
  const keyClaimsFormatted = onlyClaimsTokenArr.map(([key, value]) => [
    ...key.split('/').slice(-1),
    value
  ])
  const claims = Object.fromEntries(keyClaimsFormatted)

  return claims
}
