import { createStoreon } from 'storeon'
import { routerNavigate } from '@storeon/router'
import { storeonDevtools } from 'storeon/devtools'

import dayjs from 'dayjs'
import 'dayjs/locale/es-us'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

// add other state fragments here
import router from './router'
import modal from './modal'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.locale('es-us')

const store = createStoreon([
  router,
  modal,
  import.meta.env.DEV && storeonDevtools
])
export default store

const navigate = (url: string | undefined) =>
  store.dispatch(routerNavigate, url)

export { navigate }
