import { useEffect } from 'react'
import { clarity } from 'react-microsoft-clarity'
import { useAuthContext } from './AuthProvider'
import { parseJwt } from './utils'

/*
  This is the use auth hook, it returns the logged in user
  This is the only place that should modify the user and set isLoaded
  DO NOT call this hook more than once in a single render
*/
const useAuth = () => {
  const { auth0Client, user, setUser, setParsedToken } = useAuthContext()

  useEffect(() => {
    if (!user.isLoaded && auth0Client?.isAuthenticated) {
      // the client is not actually authenticated, but it has loaded
      ;(async () => {
        const isAuthenticated = await auth0Client.isAuthenticated()

        if (setUser && setParsedToken) {
          if (isAuthenticated) {
            if (h4benv.H4B_CLARITY_ID) {
              clarity.init(h4benv.H4B_CLARITY_ID)
            }

            const userData = await auth0Client.getUser()
            const token = await auth0Client.getTokenSilently()
            const parsedToken = parseJwt(token)

            await auth0Client.checkSession({ ignoreCache: true })

            if (clarity.hasStarted()) {
              clarity.identify(userData?.email ?? '', { name: userData?.name })
            }

            setParsedToken({
              permissions: parsedToken.permissions ?? [],
              claims: parsedToken.claims
            })
            setUser({
              userData,
              isLoaded: true,
              isAuthenticated: true,
              token
            })
          } else {
            setUser({
              userData: {},
              isLoaded: true,
              isAuthenticated: false,
              token: ''
            })
          }
        }
      })()
    }
  }, [auth0Client])

  return {
    auth0Client,
    user
  }
}

export default useAuth
