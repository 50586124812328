import { Suspense, useMemo } from 'react'
import { Layouts, FullLoading } from '@h4b-dev/components'
import { navigate } from '@store'
import { useSuscriptionTrackerByRoute } from '@h4b-dev/payments-monitor-modules'
import {
  MenuItem,
  AccountSubMenuItem,
  Auth0UserData,
  ParsedToken,
  ViewActionsPermissions
} from '@custom-types'
import { getActionsByView } from '@pages-utils'
import { UIModeSwitch } from '@/components'

import { ReactComponent as N1coMenuLogo } from './icons/logo.svg'
import { ReactComponent as N1coMenuLogoMini } from './icons/logo-mini.svg'
import { ReactComponent as YummyMenuLogo } from './icons/yummy-black.svg'
import { ReactComponent as YummyMenuLogoMini } from './icons/mini-yummy-black.svg'
import { ReactComponent as UsersIcon } from '/public/images/users.svg'
import { ReactComponent as ShieldIcon } from '/public/images/shield.svg'
import { ReactComponent as ErrorMessageIcon } from '/public/svg/error-message.svg'

import styles from './styles'

type Props = {
  Component: React.ElementType
  route: any
  user: {
    isAuthenticated: boolean
    isLoaded: boolean
    token: string
    userData: Auth0UserData
  }
  isSlave: boolean
  userActionsInThisView: ViewActionsPermissions
  parsedToken?: ParsedToken
}

const {
  H4B_AUTH_USER_ROLES: USER_ROLES_PATH,
  H4B_CURRENT_BRAND: CURRENT_BRAND
} = h4benv

const menuItems: MenuItem[] = [
  {
    key: 'real-time-dashboard',
    label: 'Dashboard',
    target: '/',
    iconUrl: '/images/graphics.svg'
  },
  {
    key: 'customers',
    label: 'Clientes',
    target: '/customers',
    iconUrl: '/images/users.svg'
  },
  {
    key: 'payment-requests',
    label: 'Solicitudes de pago',
    target: '/payment-requests',
    iconUrl: '/images/search-payment.svg'
  },
  {
    key: 'charge-requests',
    label: 'Historial de cargos',
    target: '/charge-requests',
    iconUrl: '/images/card-atm.svg'
  },
  {
    key: 'physical-acq-charges',
    label: 'Adquirencia física',
    target: '/physical-acq-charges',
    iconUrl: '/images/pos.svg'
  },
  {
    key: 'chargeback-cases',
    label: 'Contracargos',
    target: '/chargeback-cases',
    iconUrl: '/images/chargeback.svg'
  },
  {
    key: 'refund-requests',
    label: 'Reintegros',
    target: '/refund-requests',
    iconUrl: '/images/refund.svg'
  },
  {
    key: 'support-cases',
    label: 'Casos de soporte',
    target: '/support-cases',
    iconUrl: '/images/support.svg'
  }
]

const accountSubmenuItems: AccountSubMenuItem[] = [
  {
    key: 'users-management',
    label: 'Usuarios',
    target: '/users-management',
    icon: UsersIcon
  },
  {
    key: 'roles-management',
    label: 'Roles',
    target: '/roles-management',
    icon: ShieldIcon
  },
  {
    key: 'error-messages-management',
    label: 'Mensajes de error',
    target: '/error-messages-management',
    icon: ErrorMessageIcon
  }
]

const getMenuLogo = () => {
  if (CURRENT_BRAND === 'yummy') return YummyMenuLogo
  return N1coMenuLogo
}

const getMenuLogoMini = () => {
  if (CURRENT_BRAND === 'yummy') return YummyMenuLogoMini
  return N1coMenuLogoMini
}

interface GetOnlyAvailableItemsProps<T> {
  userPermissions: string[]
  items: T[]
}

const getOnlyAvailableItems = <T extends MenuItem | AccountSubMenuItem>({
  userPermissions,
  items
}: GetOnlyAvailableItemsProps<T>): T[] =>
  items.filter((item) => {
    const actionsForThisItem = getActionsByView({
      userPermissions,
      view: item.key
    })
    const haveReadPermission = actionsForThisItem.read !== undefined
    return haveReadPermission
  })

const isRouteHidden = (route: string, hiddenRoutes: string[]) =>
  hiddenRoutes.some((hiddenRoute) => route.includes(hiddenRoute))

const SusMainMenu = ({
  Component,
  route,
  user,
  parsedToken,
  userActionsInThisView,
  isSlave = false
}: Props) => {
  const userRoles = user?.userData?.[USER_ROLES_PATH] || []

  const { getRealTimeProps } = useSuscriptionTrackerByRoute({ route })
  const menuProps = useMemo(() => {
    const hiddenRoutes = (h4benv.H4B_HIDDEN_ROUTES?.split(',') ?? []).filter(
      (route) => route !== ''
    )
    const filteredMenuItems = menuItems.filter(
      (item) => !isRouteHidden(item.key, hiddenRoutes)
    )

    return {
      MenuLogo: getMenuLogo(),
      MenuLogoMini: getMenuLogoMini(),
      itemData: getOnlyAvailableItems<MenuItem>({
        userPermissions: parsedToken?.permissions ?? [],
        items: filteredMenuItems
      }),
      activeItem: route.path,
      userRoles
    }
  }, [menuItems])
  const topBarProps = useMemo(
    () => ({
      selects: [],
      accountAvatarProps: {
        source: user.userData.picture || '',
        userName: user.userData.name || 'No session'
      },
      userRoles,
      accountSubmenuItems: getOnlyAvailableItems<AccountSubMenuItem>({
        userPermissions: parsedToken?.permissions ?? [],
        items: accountSubmenuItems
      })
    }),
    [user, accountSubmenuItems]
  )

  return (
    <div className={styles}>
      <Layouts.MainMenu
        title={route.match.title}
        menuProps={menuProps}
        topBarProps={topBarProps}
        navActions={
          route.match.navActions
            ? route.match.navActions.map(
                (action: { target: string | undefined }) => ({
                  ...action,
                  onClick: () => navigate(action.target)
                })
              )
            : []
        }
        disabledActions={isSlave}
        breadcrumbPaths={route.match.breadcrumbs}
        activeItem={route.match.menuActive}
      >
        <UIModeSwitch />
        <Suspense fallback={<FullLoading />}>
          {Component ? (
            <Component
              {...{
                ...route.match.props,
                userActionsInThisView,
                ...getRealTimeProps()
              }}
            />
          ) : null}
        </Suspense>
      </Layouts.MainMenu>
    </div>
  )
}

export default SusMainMenu
