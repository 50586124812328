import { ViewActionsPermissions } from '@custom-types'

const EXCLUDED_PAGES_FOR_PERMISSION_VALIDATION = ['logout', 'authorize']

export enum ActionTypeEnum {
  create = 'create',
  read = 'read',
  update = 'update',
  delete = 'delete',
  download = 'download',
  send = 'send',
  upload = 'upload'
}

// FORMAT TO FOLLOW -> ACTION:VIEW:SPECIFIC
export enum PermissionLevelEnum {
  ACTION = 0,
  VIEW = 1,
  SPECIFIC = 2
}

type GetDataProps = {
  getAction: (values: any) => any
  variables: any
  dataName: string
}

type PermissionCheckerProps = {
  userPermissions: string[]
  permissionsToCheck: string[]
}

type getActionsByViewProps = {
  userPermissions: string[]
  view: string
}

type RemovePrefixWordProps = {
  prefix: string
  str: string
}

export const getData = async ({
  getAction,
  variables,
  dataName
}: GetDataProps) => {
  try {
    return await (
      await getAction({ variables })
    ).data?.[dataName]
  } catch (error) {
    console.log(`error in getAction: ${dataName} `, error)
    return null
  }
}

export const pushNewUrl = (newUrl: string) => {
  if (window.history.pushState) {
    const createNewUrl = `${window.location.pathname}?${newUrl}`
    window.history.pushState({ path: createNewUrl }, '', createNewUrl)
  }
}

export const getUrlParamsObject = () => {
  const url = new URL(window.document.location.href)
  return new URLSearchParams(url.search.slice(1))
}

export const getAnURLParam = (key: string) => {
  const params = getUrlParamsObject()

  if (params.has(key)) return params.get(key)
  return undefined
}

export const setURLParam = (key: string, value: string) => {
  const params = getUrlParamsObject()

  if (params.has(key)) params.set(key, value)
  else params.append(key, value)

  pushNewUrl(params.toString())
}

export const deleteURLParam = (key: string) => {
  const params = getUrlParamsObject()

  if (params.has(key)) {
    params.delete(key)
    pushNewUrl(params.toString())
  }
}

export const textChecker = (
  text?: string | number | null,
  defaultValue?: string
) => {
  if (text && text !== 'Invalid Date') return text
  return defaultValue ?? '--'
}

export const equals = (a: any, b: any): boolean => {
  if (a === b) return true
  if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime()
  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object'))
    return a === b
  if (a.prototype !== b.prototype) return false
  const keys = Object.keys(a)
  if (keys.length !== Object.keys(b).length) return false
  return keys.every((k) => equals(a[k], b[k]))
}

const splitPermissions = (userPermissions: string[]) =>
  userPermissions.map((permission) => permission.split(':'))

/*
  userPermissions: string[] -> permisos de una acción específica para el usuario (si este existe y viene vacío, significa que tiene permiso general *super usuario*)
  permissionsToCheck: string[] -> permisos requeridos para una vista/componente/acción específica
*/
export const permissionChecker = ({
  userPermissions,
  permissionsToCheck
}: PermissionCheckerProps): boolean => {
  if (userPermissions.includes('all') || permissionsToCheck.length === 0)
    return true

  return userPermissions.some((permission) =>
    permissionsToCheck.includes(permission)
  )
}

export const getActionsByView = ({
  userPermissions,
  view
}: getActionsByViewProps) => {
  const splittedPermisions = splitPermissions(userPermissions)
  const viewsFiltered = splittedPermisions.filter(
    (permissionArr) => permissionArr[PermissionLevelEnum.VIEW] === view
  )
  let actionsFormatted: ViewActionsPermissions = {}

  if (EXCLUDED_PAGES_FOR_PERMISSION_VALIDATION.includes(view)) {
    actionsFormatted = { read: [] }
    return actionsFormatted
  }

  viewsFiltered.forEach((action) => {
    const actionName: ActionTypeEnum =
      ActionTypeEnum[action[PermissionLevelEnum.ACTION] as ActionTypeEnum]
    const actionValue = action[PermissionLevelEnum.SPECIFIC]
    let actionValues: string[] = [
      ...(actionsFormatted[actionName] ?? []),
      actionValue ?? 'all'
    ]

    actionsFormatted = {
      ...actionsFormatted,
      [actionName]: actionValues
    }
  })

  return actionsFormatted
}

export const removePrefixWord = ({ prefix, str }: RemovePrefixWordProps) => {
  if (str.startsWith(prefix) && str.trim().split(/\s+/).length > 1)
    return str.replace(prefix, '').trim()

  return str
}

// utils to handle the items quantity to show in the table rows
export const getItemsToShow = (items: any[], maxItemsToShow: number) => {
  if (items.length > maxItemsToShow)
    return items
      .slice(0, maxItemsToShow)
      .concat(`surplus: + ${items.length - maxItemsToShow}`)

  return items
}

export const isASurplus = (item: string) => item.includes('surplus:')

export const getSurplusValue = (item: string) => {
  const surplus = item.split('surplus: ')
  return surplus[surplus.length - 1]
}
